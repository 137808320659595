@font-face {
  font-weight: 100;
  font-family: "PF Square Sans Pro";
  font-style: italic;
  src: url("PFSquareSansPro-ThinItalic.eot");
  src: local("PFSquareSansPro-ThinItalic"),
    url("PFSquareSansPro-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-ThinItalic.woff2") format("woff2"),
    url("PFSquareSansPro-ThinItalic.woff") format("woff"),
    url("PFSquareSansPro-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-weight: 100;
  font-family: "PF Square Sans Pro";
  font-style: normal;
  src: url("PFSquareSansPro-Thin.eot");
  src: local("PFSquareSansPro-Thin"),
    url("PFSquareSansPro-Thin.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Thin.woff2") format("woff2"),
    url("PFSquareSansPro-Thin.woff") format("woff"),
    url("PFSquareSansPro-Thin.ttf") format("truetype");
}

@font-face {
  font-weight: 300;
  font-family: "PF Square Sans Pro";
  font-style: italic;
  src: url("PFSquareSansPro-LightItalic.eot");
  src: local("PFSquareSansPro-LightItalic"),
    url("PFSquareSansPro-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-LightItalic.woff2") format("woff2"),
    url("PFSquareSansPro-LightItalic.woff") format("woff"),
    url("PFSquareSansPro-LightItalic.ttf") format("truetype");
}

@font-face {
  font-weight: bold;
  font-family: "PF Square Sans Pro";
  font-style: normal;
  src: url("PFSquareSansPro-Bold.eot");
  src: local("PFSquareSansPro-Bold"),
    url("PFSquareSansPro-Bold.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Bold.woff2") format("woff2"),
    url("PFSquareSansPro-Bold.woff") format("woff"),
    url("PFSquareSansPro-Bold.ttf") format("truetype");
}

@font-face {
  font-weight: normal;
  font-family: "PF Square Sans Pro";
  font-style: normal;
  src: url("PFSquareSansPro-Regular.eot");
  src: local("PFSquareSansPro-Regular"),
    url("PFSquareSansPro-Regular.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Regular.woff2") format("woff2"),
    url("PFSquareSansPro-Regular.woff") format("woff"),
    url("PFSquareSansPro-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 300;
  font-family: "PF Square Sans Pro";
  font-style: normal;
  src: url("PFSquareSansPro-Light.eot");
  src: local("PFSquareSansPro-Light"),
    url("PFSquareSansPro-Light.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Light.woff2") format("woff2"),
    url("PFSquareSansPro-Light.woff") format("woff"),
    url("PFSquareSansPro-Light.ttf") format("truetype");
}

@font-face {
  font-weight: 900;
  font-family: "PF Square Sans Pro Extra";
  font-style: italic;
  src: url("PFSquareSansPro-ExtraBlackItalic.eot");
  src: local("PFSquareSansPro-ExtraBlackItalic"),
    url("PFSquareSansPro-ExtraBlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("PFSquareSansPro-ExtraBlackItalic.woff2") format("woff2"),
    url("PFSquareSansPro-ExtraBlackItalic.woff") format("woff"),
    url("PFSquareSansPro-ExtraBlackItalic.ttf") format("truetype");
}

@font-face {
  font-weight: normal;
  font-family: "PF Square Sans Pro";
  font-style: italic;
  src: url("PFSquareSansPro-Italic.eot");
  src: local("PFSquareSansPro-Italic"),
    url("PFSquareSansPro-Italic.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Italic.woff2") format("woff2"),
    url("PFSquareSansPro-Italic.woff") format("woff"),
    url("PFSquareSansPro-Italic.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "PF Square Sans Pro";
  font-style: italic;
  src: url("PFSquareSansPro-MediumItalic.eot");
  src: local("PFSquareSansPro-MediumItalic"),
    url("PFSquareSansPro-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-MediumItalic.woff2") format("woff2"),
    url("PFSquareSansPro-MediumItalic.woff") format("woff"),
    url("PFSquareSansPro-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "PF Square Sans Pro";
  font-style: normal;
  src: url("PFSquareSansPro-Medium.eot");
  src: local("PFSquareSansPro-Medium"),
    url("PFSquareSansPro-Medium.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-Medium.woff2") format("woff2"),
    url("PFSquareSansPro-Medium.woff") format("woff"),
    url("PFSquareSansPro-Medium.ttf") format("truetype");
}

@font-face {
  font-weight: bold;
  font-family: "PF Square Sans Pro";
  font-style: italic;
  src: url("PFSquareSansPro-BoldItalic.eot");
  src: local("PFSquareSansPro-BoldItalic"),
    url("PFSquareSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-BoldItalic.woff2") format("woff2"),
    url("PFSquareSansPro-BoldItalic.woff") format("woff"),
    url("PFSquareSansPro-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-weight: 900;
  font-family: "PF Square Sans Pro Extra";
  font-style: normal;
  src: url("PFSquareSansPro-ExtraBlack.eot");
  src: local("PFSquareSansPro-ExtraBlack"),
    url("PFSquareSansPro-ExtraBlack.eot?#iefix") format("embedded-opentype"),
    url("PFSquareSansPro-ExtraBlack.woff2") format("woff2"),
    url("PFSquareSansPro-ExtraBlack.woff") format("woff"),
    url("PFSquareSansPro-ExtraBlack.ttf") format("truetype");
}
