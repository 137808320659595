@font-face {
  font-weight: 200;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-ExtraLight.ttf");
  src: local("Oswald-ExtraLight"),
    url("Oswald-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-weight: 300;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-Light.ttf");
  src: local("Oswald-Light"), url("Oswald-Light.ttf") format("truetype");
}

@font-face {
  font-weight: 400;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-Regular.ttf");
  src: local("Oswald-Regular"), url("Oswald-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 500;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-Medium.ttf");
  src: local("Oswald-Medium"), url("Oswald-Medium.ttf") format("truetype");
}

@font-face {
  font-weight: 600;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-SemiBold.ttf");
  src: local("Oswald-SemiBold"), url("Oswald-SemiBold.ttf") format("truetype");
}

@font-face {
  font-weight: 700;
  font-family: "Oswald";
  font-style: normal;
  src: url("Oswald-Bold.ttf");
  src: local("Oswald-Bold"), url("Oswald-Bold.ttf") format("truetype");
}
